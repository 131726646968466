
.contentSectionWrapper{

    padding: 78px 0px 0px 0px;
    background-color: #F3F4F6;
    border-radius: 5px;
}
@media only screen and (min-width: 600px) {
  .contentSectionWrapper{
    padding: 78px;
}
}
.mainCard {
    margin-bottom: 20px;
    .chartmainWrapper {
      width: 100%;
      max-width: 1600px;
      .chartHeader {
        font-weight: bolder;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .chart {
        width: 100%;
      }
    }
  }

  p {
    display: block;
    margin-block-start: 0.5px;
    margin-block-end: 0.5px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1px;
    margin-block-end: 1px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.btn-naranja {
  background-color: #FF4935 !important;
  color:#FFFF;
  padding: 20px 25px;
}
