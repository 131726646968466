:global(.ant-layout-header){
  &.topHeader{
    position: fixed;
    top: 0;
    z-index: 1;
    width: 95%;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border-bottom: 1px solid rgb(244, 244, 244);
    box-shadow: rgb(244, 244, 244) 0px 0px 10px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 32px;
  img {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.inputField {
  width: 402px;
  height: 45px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  background-color: #f9fafb;
  color: #6b7280;
  input {
    background-color: #f9fafb;
    margin-left: 1rem;
  }
  ::placeholder {
    color: #6b7280;
  }
  &:focus-within {
    border-color: #e5e7eb;
  }
  &:hover {
    border-color: #e5e7eb;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.topAvatar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footerCard {
  margin: 24px;
  .footer {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    flex-wrap: wrap;

    .socialIcons {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
@media (max-width: 680px) {
  .footerCard {
    margin: 10px;
    .footer {
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      width: 100%;
    }
  }
}

.titulo {
  color: #FF4935;
}