body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.naranja {
  color: #FF4935 !important;
}

.icono {
  padding-right: 18px;
}

.fullfooter {
  background-color: transparent;
  /* background-image: linear-gradient(180deg, #575756 74%, #3A3A3A 100%); */
  background-image: linear-gradient(180deg, #F4F4F4 74%, #c6bbbb 100%);
}
.textfooter {
  color: rgba(255,255,255,0.65) !important;
}

.derecha {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}

.btn-naranja {
  background-color: #FF4935;
  color:#FFFF;
  font-weight: 700;
  line-height:1.5em;
}


.dash-solucion {
  height: 90px;
  filter: brightness( 60% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 219deg );
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
  text-align: center;
}

.dash-destinos {
  height: 50px;
  filter: brightness( 60% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 219deg ); 
  box-shadow: -3px -12px 27px -13px rgba(0,0,0,0.22);
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
}

.card {
  border-radius: 12px;
}
.cardfoot {
  display: block;
  margin-top: auto;
  margin-bottom: 0;
}